export const createListFilters = (count: number) => {
  const list = []

  for (let index = 1; index <= count; index++) {
    list.push({
      label: `${index}+`,
      value: index,
    })
  }

  return list
}
